<template>
  <popover v-model="SiteMenu.popoverVisible" :visible-arrow="false" :width="width" append-to-body trigger="manual" placement="left-start" popper-class="nav-edit-popper">
    <div class="popover-content">
      <div class="popover-content__header px-3 flex items-center justify-between">
        <div class="title text-white text-12">{{ title }}</div>
        <i class="el-icon-close text-white text-18 cursor-pointer" @click="close" />
      </div>
      <div class="popover-content__body p-3">
        <nav-edit :target="target" :keys="keys" :target-key="targetKey" show-name :target-index="targetIndex" />
      </div>
    </div>
    <div slot="reference" />
  </popover>
</template>

<script>
import { Popover } from "element-ui";
import NavEdit from '~/site/components/navEdit.vue'
export default {
  name: 'NavEditPopover',
  components: {
    Popover,
    NavEdit
  },
  props: {
    target: {
      type: Object,
      default() {
        return {}
      }
    },
    keys: {
      type: Array,
      default() {
        return []
      }
    },
    targetKey: {
      type: String,
      default: ''
    },
    targetIndex: {
      type: [Number, String],
      default: ''
    },
    width: {
      type: Number,
      default: 220
    },
    title: {
      type: String,
      default: '编辑菜单项'
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.SiteMenu.closePopover()
    }
  }
}
</script>

<style lang="less">
.nav-edit-popper {
  background-color: @widget-menu-bg;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.36);
  border-radius: @border-radius-base;
  .popover-content {
    &__header {
      border-bottom: 1px solid @border-color-base;
      height: 40px;
    }
  }
}
</style>
