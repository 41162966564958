<template>
  <div class="carousel-menu-wrap">
    <menu-title :close="close">{{targetTitle}}</menu-title>
    <menu-item class="menu-item-switch" :label="$t('menu.layout')">
      <!-- 描述 -->
      <menu-switch
        :label="$t('menu.describe')"
        :value="target.descriptionShow"
        @change="(v)=> target.descriptionShow = v"
      ></menu-switch>
    </menu-item>
    <menu-divider />
    <menu-item label="图片" size-tip="(建议比例4:3)">
      <!-- 图片 -->
      <menu-background-image
        :target="target"
        background-blur-disabled
        color-disabled
        event-key="collapse-img"
      />
    </menu-item>
    <delete-block-dialog-trigger class="mt-4" @delete-block="deleteBlock" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import cloneDeep from "lodash.clonedeep";
import menuTitle from '~/components/menu/menuTitle'
import MenuItem from '~/components/menu/menuItem'
import MenuSwitch from '~/components/menu/menuSwitch.vue'
import DeleteBlockDialogTrigger from '~/site/components/editMenu/deleteBlockTrigger'
import MenuDivider from "~/components/menu/divider.vue";
import {insertStep} from "~/utils/event";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue"

export default {
  name: 'EditMenuCollapse',
  components: {
    MenuBackgroundImage,
    MenuDivider,
    DeleteBlockDialogTrigger,
    MenuItem,
    MenuSwitch,
    menuTitle
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    childIndex: {
      type: [Number, undefined],
      default() {
        return undefined
      },
    },
  },
  computed: {
    target() {
      return this.model?.__selectChild || {}
    },
    targetTitle() {
      return `${this.$t('menu.collapseRow')}${this.childIndex === undefined ? '' : this.childIndex + 1}`
    },
  },
  methods: {
    ...mapMutations({
      ADD_MENU_KEY: 'menu/ADD_MENU_KEY',
      REMOVE_MENU_KEY: 'menu/REMOVE_MENU_KEY',
    }),
    close() {
      this.SiteMenu.close()
    },
    deleteBlock() {
      insertStep(this, ['children'], cloneDeep(this.model.children))
      this.$store.commit('editor/CLEAR_STORAGE_STEP')
      this.close()
      this.model.deleteMenuMethod()
    },
  }
}
</script>

<style lang="less">
</style>
