<template>
    <div v-if="show" class="im-model-wrap pay-result" :class="paystatus">
        <div class="im-model">
            <div class="im-model-head">
                <div class="top-info flex justify-between items-center">
                    <!-- todo -->
                    <div v-if="payInfo && payInfo.project" class="app-name"> {{ payInfo.project.names[lang] || payInfo.project.names.en }}  </div>
                    <div v-if="payInfo && userInfo" class="user-name">{{ userInfo.name }}</div>
                </div>
            </div>

            <!-- 750 样式 -->
            <m-header class="mobile-head" from="result" :class="paystatus" @back="closeReslut">{{$t('siteBuild.pay.result')}}</m-header>

            <div v-imloading='spinning && !loopEdNums' class="im-model-body">
                <!-- 支付结果 -->
                <div class="pay-result-info">
                    <div v-if="payInfo" class="pay-status" :class="paystatus">
                        <div class="top flex justify-center items-center">
                            <div class="icon">
                                <im-icon v-if="paystatus === 'paid'" icon='icon-zhifuwanchengzhuangtai'></im-icon>
                                <im-icon v-if="others.includes(paystatus)" icon='icon-zhifushibaizhuangtai'></im-icon>
                                <im-icon v-if="paystatus === 'failed'" icon='icon-zhifushibaizhuangtai'></im-icon>
                                <im-icon v-if="paystatus === 'pending'" :class="{'pending': looping}" icon='icon-zhifuzhongzhuangtai-xin'  @click="refreshPayStatus()"></im-icon>
                            </div>
                            <div class="text">{{$t(`siteBuild.pay.${paystatus}`)}}</div>
                        </div>
                        <div class="bill-top">
                        </div>
                    </div>
                    <div v-if="payInfo" class="order-wrap" :class="paystatus">
                        <div class="order-info ">
                            <div class="top"></div>
                            <div class="order">
                                <div class="scorll-wrap" :class="{nopadding: showMore}">
                                    <!-- 应用 -->
                                    <div v-if="payInfo.project"  class="item app flex justify-between mobile-justify-left">
                                        <div class="left">{{$t('siteBuild.pay.app')}}{{device==='mobile'?':':''}}</div>
                                        <div class="right">{{ payInfo.project.names[lang] }}</div>
                                    </div>
                                    <!-- 交易ID -->
                                    <div class="item order-id flex justify-between mobile-justify-left">
                                        <div class="left">{{$t('siteBuild.pay.payId')}}{{device==='mobile'?':':''}}</div>
                                        <div class="right">{{payInfo._id}}</div>
                                    </div>
                                    <!-- 交易时间 -->
                                    <div v-show="showMore" class="item flex justify-between mobile-justify-left">
                                        <div class="left">{{$t('siteBuild.pay.payTime')}}{{device==='mobile'?':':''}}</div>
                                        <div class="right">{{ payTime }}</div>
                                    </div>
                                    <!-- 商家 -->
                                    <!-- <div v-show="showMore" class="item flex justify-between mobile-justify-left">
                                        <div class="left">{{$t('siteBuild.pay.business')}}{{device==='mobile'?':':''}}</div>
                                        <div class="right">{{payInfo.merchant_name}}</div>
                                    </div> -->
                                    <!-- 用户 -->
                                    <div v-if="payInfo.payer && payInfo.payer.name" v-show="showMore" class="item flex justify-between mobile-justify-left">
                                        <div class="left">{{$t('siteBuild.pay.payer')}}{{device==='mobile'?':':''}}</div>
                                        <div class="right">{{payInfo.payer.name}}</div>
                                    </div>
                                    <!-- 购买商品 -->
                                    <div v-if="payInfo.items" v-show="showMore" class="item flex items-center goods-wrap mobile-justify-left">
                                        <div class="left">{{$t('siteBuild.pay.buyGood')}}{{device==='mobile'?':':''}}</div>
                                        <div  class="goods">
                                            <div v-for="g in payInfo.items" :key="g.id" class="g-item flex justify-between">
                                                <div class="g-left">
                                                    {{g.name}}
                                                </div>
                                                <div class="g-right">
                                                    {{g.currency}} {{g.price}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 小计 -->
                                    <div v-show="showMore" class="item subTotal app flex justify-between">
                                        <div class="left">{{$t('siteBuild.pay.subTotal')}}{{device==='mobile'?':':''}}</div>
                                        <div class="right">{{payInfo.currency}} {{payInfo.amount}}</div>
                                    </div>
                                    <!-- 销售税 -->
                                    <div v-show="showMore && !payInfo.with_tax" class="item tax app flex justify-between">
                                        <div class="left">{{$t('siteBuild.pay.tax')}}{{device==='mobile'?':':''}}</div>
                                        <div class="right">{{payInfo.currency}} {{payInfo.taxes_value || 0.00}}</div>
                                    </div>
                                    <!-- 订单总额： -->
                                    <div class="top-total">
                                        <span> <span class="lable">{{$t('siteBuild.pay.orderAmount')}}：{{payInfo.currency}}</span> {{payInfo.total_amount}}</span>
                                    </div>
                                </div>

                                <div class="more">
                                    <div class="flex justify-center items-center cursor-pointer">
                                        <div class="flex justify-center items-center"  @click="showMore = !showMore">
                                            <im-icon v-show="showMore" icon='icon-shang-yincang'></im-icon>
                                            <im-icon v-show="!showMore" icon='icon-xia-zhankai'></im-icon>
                                            <span class="text">{{$t( showMore ? 'siteBuild.pay.hideMoreText' : 'siteBuild.pay.showMoreText')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- 订单总额： -->
                                <!-- <div class="total flex justify-between">
                                    <div class="left">{{$t('siteBuild.pay.orderAmount')}}:</div>
                                    <div class="right">{{payInfo.currency}} {{payInfo.amount}} </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <form v-show="false" id="payment-form">
                        <div id="payment-element">
                            <!--Stripe.js injects the Payment Element-->
                        </div>
                        <button v-show="false" id="submit">
                            <div id="spinner" class="spinner hidden"></div>
                            <span id="button-text">Pay now</span>
                        </button>
                        <div id="payment-message" class="hidden"></div>
                    </form>
                    <!-- 查询失败了 -->
                    <div v-show="fail"> {{$t('siteBuild.pay.queryFail')}} </div>
                </div>
            </div>
            <div class="im-model-foot">
                <!-- <span>{{$t('common.powered')}}</span> -->
            </div>
            <span class="close" @click="closeReslut">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#E1E1E1"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0397 5.76885L5.76656 13.0419L4.85742 12.1328L12.1305 4.85971L13.0397 5.76885Z" fill="#8E8E8E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.76104 4.9662L13.0341 12.2393L12.125 13.1484L4.8519 5.87534L5.76104 4.9662Z" fill="#8E8E8E"/>
                </svg>
            </span>
        </div>
    </div>
</template>
<script>
import { mapState} from 'vuex'
import MHeader from './mobilehead.vue'
import tools from '~/utils'
export default {
    components: {
        MHeader,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        device: {
            type: String,
            default: 'desktop'
        },
    },
    data () {
        return {
            show: false,
            paystatus: 'unpaid', // 订单状态，默认未支付
            spinning: false,
            showMore: false, // 显示更多
            payInfo: null,
            timer: null,
            fail: false,
            platform: '', // 支付平台
            lang: '',
            others: ['unpaid', 'cancelled', 'refund_pending', 'refunded', 'refund_failed', 'refund_cancelled'],
            loopTime: 0,
            loopEdNums: 0,
            looping: true,
            loopNums: 60,// 轮询查询次数
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.siteUserInfo,
            projectInfo: state => state.project.info,
        }),
        payTime () {
            const time = this.payInfo.pay_time;
            if (time) {
                return tools.formatDate(time);
            } else {
                return '--';
            }

        }
    },
    watch: {
         value (newVal, oldVal) {
            this.show = newVal;
            this.platform = tools.getQueryString('platform');
            if (this.show) {
                // 通用获取订单详情
                clearTimeout(this.loopTime)
                this.getOrderDetail();
            }
        },
        show (val) {
            this.$emit('input', val)
        }
    },
    mounted () {
        this.show = this.value;
        this.platform = tools.getQueryString('platform');
        this.loopTime = 0;
        this.loopEdNums = 0;
        this.lang = this.$route.params.lang || 'en';
    },
    methods: {
        // 初始化stripe对象 (stripe)
        // todo pk_test
        async initialize () {
            // const apiKey = process.env.STRIPE_API_KEY;
            // this.stripe = await loadStripe(apiKey);
        },
        // 查询支付情况 (stripe)
        async checkStatus (refresh) {

            const clientSecret = tools.getQueryString("payment_intent_client_secret");

            if (!refresh) { // 手动刷新支付状态
                if (!clientSecret) {
                    return;
                }
            }

            const res = await this.stripe.retrievePaymentIntent(clientSecret);
            switch (res.paymentIntent.status) {
                case "succeeded":
                    this.initPaystatus('succeeded')
                    break;
                case "processing":
                    this.initPaystatus('processing')
                    break;
                case "requires_payment_method":
                    this.initPaystatus('requires_payment_method')
                    break;
                default:
                    this.initPaystatus('requires_payment_method')
                    break;
            }
        },
        // 通用获取订单详情
        getOrderDetail () {
            const isSandbox = tools.getQueryString('sandbox')
            const token = tools.getQueryString('access_token')
            this.spinning = true;
            const api =  +isSandbox ? this.$api.koppay.getOrderDetailSandbox :this.$api.koppay.getOrderDetail
            api(token).then((result)=>{
                // let searchStr = window.location.search;
                // const pathname = window.location.pathname;
                // this.$logger.capturePayMessage('查询订单结果access_token:' + token)
                this.payInfo = result;
                this.spinning = false;
                // 兼容各个支付平台支付状态
                this.initPaystatus(result.status);

                // 查询过一次以后，隐藏支付结果展示弹窗
                // if (!searchStr.includes('&isHide=true')){
                //     searchStr+='&isHide=true';
                //     this.$router.replace(`${pathname}${searchStr}`);
                // }

            }).finally(()=>{
                this.spinning = false;
            })
        },
        // todo 兼容各个支付平台支付状态
        initPaystatus (status) {
            // 未支付
            const unpaid = [
                'UNPAID', // dlocal
            ];
            // 支付中
            const pending = [
                'processing', // stripe
                'PENDING', // dlocal
                'PENDING_3DS',// dlocal
            ];
            // 已支付
            const paid = [
                'succeeded', // stripe
                'PAID', // dlocal
            ];
            // 支付失败
            const failed = [
                'requires_payment_method', // stripe
                'FAILED', // dlocal
            ];
            // 支付取消
            const cancelled = [
                'CANCELLED', // dlocal
            ];
            // 退款中
            const refund_pending = [
                'REFUND_PENDING', // dlocal
            ];
            // 已退款
            const refunded = [
                'REFUNDED', // dlocal
            ];
            // 退款失败
            const refund_failed = [
                'REFUND_FAILED', // dlocal
            ];
            // 退款取消
            const refund_cancelled = [
                'REFUND_CANCELLED', // dlocal
            ];

            // 第二次及以后，不是pending则结束查询，清掉定时器
            if (!pending.includes(status) && this.loopEdNums) {
                this.looping = false;
                this.loopEdNums = 0;
                clearTimeout(this.loopTime);
            }

            if (unpaid.includes(status)) {
                this.paystatus = 'unpaid'
            } else if (pending.includes(status)){
                this.paystatus = 'pending'

                if (this.loopEdNums < this.loopNums) {
                    this.loopRefresh();
                } else {
                    this.looping = false;
                    this.loopEdNums = 0;
                    clearTimeout(this.loopTime);
                }
            } else if (paid.includes(status)){
                this.paystatus = 'paid'
            } else if (failed.includes(status)){
                this.paystatus = 'failed'
            } else if (cancelled.includes(status)){
                this.paystatus = 'cancelled'
            } else if (refund_pending.includes(status)){
                this.paystatus = 'refund_pending'
            } else if (refunded.includes(status)){
                this.paystatus = 'refunded'
            } else if (refund_failed.includes(status)){
                this.paystatus = 'refund_failed'
            } else if (refund_cancelled.includes(status)){
                this.paystatus = 'refund_cancelled'
            } else { // 默认支付中
                this.paystatus = 'pending'
            }
        },

        // 手动刷新支付状态, 各个支付平台刷新方法不一样
        refreshPayStatus () {
           this.getOrderDetail();
        },

        // 轮询查询订单结果
        loopRefresh () {
            this.looping = true;
            this.loopEdNums++;
            this.loopTime = setTimeout(()=>{
                this.refreshPayStatus();
            }, 5000)
        },

        closeReslut () {
            clearTimeout(this.loopTime);
            if (this.device === 'desktop') {
                this.show = false;
            } else {
                this.$emit('close')
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .im-model-wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2021;
        background: rgba(25, 27, 66, 0.8);
        .im-model {
            max-width: 900px;
            width: 85%;
            max-height: 563px;
            min-width: 637px;
            background: #F7F8F8;
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -30%);
            .mobile-head {
                display: none!important;
            }
            .close {
                position: absolute;
                right: 18px;
                font-style: normal;
                width: 18px;
                height: 18px;
                top: 13px!important;
                cursor: pointer;
            }
            .im-model-head {
                height: 48px;
                padding:0 55px 0 24px;
                background: #fff;
                .top-info {
                    height: 48px;
                    font-size: 16px;
                    color: #4B4B4B;
                    .app-name, .user-name {
                        font-size: 16px;
                        color: #86889C;
                    }
                }
            }
            .im-model-body {
                background: #F7F8F8;
                min-width: 580px;
                position: relative;
                padding: 0!important;
            }
            .im-model-foot {
                text-align: center;
                background: #F7F8F8;
                margin-top: -1px;
                height: 48px!important;
                line-height: 48px!important;
                span {
                    font-size: 12px;
                    color: #86889C;
                }
            }
        }
    }
    .pay-result-info {
        min-height: 300px;
        .pay-status {
            height: 168px;
            padding-top: 28px;
            .top{
                height: 32px;
                line-height: 32px;
                margin-bottom: 32px;
                .im-icon {
                    fill: #fff;
                }
            }
            .icon {
                margin-right: 9px;
                .im-icon {
                    font-size: 28px;
                    vertical-align: middle;
                    fill: #fff;
                }
                svg.pending {
                    animation:pending 1s linear infinite;
                }
                @keyframes pending{
                    0%{-webkit-transform:rotate(0deg);}
                    25%{-webkit-transform:rotate(90deg);}
                    50%{-webkit-transform:rotate(180deg);}
                    75%{-webkit-transform:rotate(270deg);}
                    100%{-webkit-transform:rotate(360deg);}
                }
            }
            .text {
                font-weight: 500;
                font-size: 24px;
                color: #fff;
            }
            .bill-top {
                height: 24px;
                margin: 0 56px 0;
                border-radius: 90px;
            }
        }
        .pay-status.unpaid,
        .pay-status.cancelled,
        .pay-status.refund_pending,
        .pay-status.refunded,
        .pay-status.refund_failed,
        .pay-status.refund_cancelled {
            background: #c9c9d4;
            .bill-top {
                background: #86889c;
            }
        }
        .pay-status.pending  {
            background:  #5A47EE;
            .bill-top {
                background: #4D3CCA;
            }
        }
        .pay-status.paid {
            background: #00C970;
            .bill-top {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #00C970;
            }
        }
        .pay-status.failed {
            background:  #F16B67;
            .bill-top {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F16B67;
            }
        }

        .order-wrap {
            padding-bottom: 18px;
             .order-info {
                box-shadow: 0px 4px 16px rgba(131, 150, 156, 0.25);
                background: #fff;
                margin: 0 70px;
                margin-top: -63px;
                .top {
                    height: 24px;
                }
                .top-total {
                    text-align: center;
                    color: #1F1D29;
                    font-weight: 500;
                    font-size: 18px;
                    margin: 32px 0 22px;
                }
                .order {
                    padding: 0 0 0 24px;
                    margin-top: 10px;
                    .scorll-wrap {
                        max-height: 305px;
                        overflow: auto;
                        padding-right: 24px;
                    }
                    .scorll-wrap.nopadding {
                        padding-right: 8px;
                    }
                    // todo: 颜色
                    .scorll-wrap::-webkit-scrollbar-track {
                        background-color: #F0F2F5;
                    }
                    .scorll-wrap::-webkit-scrollbar {
                        height: 6px;
                        width: 10px;
                        background-color: #F0F2F5;
                    }
                    .scorll-wrap::-webkit-scrollbar-thumb {
                        width: 10px;
                        border-radius: 8px;
                        background-color: #C9C9D4;
                    }
                }
                .item {
                    padding: 8px 0;
                    color:  #4E5269;
                    font-size: 14px;
                    position: relative;
                    .left {
                        padding-right: 15px;
                        position: relative;
                        z-index: 2021;
                        background: #fff;
                    }
                    .right {
                        padding-left: 15px;
                        position: relative;
                        z-index: 2021;
                        background: #fff;
                    }
                }
                .item::before {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: 13px;
                    width: 100%;
                    border-bottom: 1px dashed rgba(142, 142, 142, 30%);
                }
                .item.goods-wrap {
                    display: block!important;
                    .goods{
                        max-height: 180px;
                        overflow-y: auto;
                        .left {
                            margin-bottom: 4px;
                        }
                        .g-item {
                            font-size: 14px;
                            color:  #4E5269;
                            padding: 4px 0;
                            position: relative;
                        }
                        .g-item::before {
                            display: block;
                            content: '';
                            position: absolute;
                            bottom: 9px;
                            width: 100%;
                            border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
                        }
                        .g-left {
                            padding-right: 15px;
                            padding-left: 24px;
                            background: #fff;
                            position: relative;
                            z-index: 2021;
                        }
                        .g-right {
                            padding-left: 15px;
                            background: #fff;
                            position: relative;
                            z-index: 2021;
                        }
                    }
                }
                .more {
                    margin-top: 14px;
                    padding-bottom: 30px;
                    span.text {
                        color: @primary-color;
                        font-size: 12px;
                        margin-left: 3px;
                    }
                    .im-icon {
                        fill: @primary-color;
                    }
                }
                .total {
                    border-top: 1px solid rgba(255, 255, 255, 0.3);
                    padding: 20px 0 0px 0;
                    margin-top: 8px;
                    font-size: 18px;
                    color: #FFFFFF;
                }
             }
        }
        .order-wrap.unpaid,
        .order-wrap.cancelled,
        .order-wrap.refund_pending,
        .order-wrap.refunded,
        .order-wrap.refund_failed,
        .order-wrap.refund_cancelled {
            .top {
                background: linear-gradient(180deg, #86889C 0%, rgba(201, 201, 212, 0) 100%);
            }
        }
        .order-wrap.pending {
            .top {
                background: linear-gradient(180deg, #C4BCFF 0%, rgba(189, 181, 247, 0) 100%);
            }
        }
        .order-wrap.paid {
            .top {
                background: linear-gradient(180deg, rgba(0, 171, 95, 0.4) 0%, rgba(0, 171, 95, 0) 100%);
            }
        }
        .order-wrap.failed {
            .top {
                background: linear-gradient(180deg, rgba(241, 107, 103, 0.4) 0%, rgba(241, 107, 103, 0) 100%);
            }
        }
    }
</style>
<style lang="less">
    .site-device-750 {
        .im-model-wrap.pay-result {
            .im-model {
                position: relative;
                transform: translate(0, 0px);
                top: 0;
                left: 0;
                .mobile-head {
                    display: flex!important;
                    .center {
                        color: #FFFFFF;
                    }
                }
                .im-model-body {
                    min-width: auto;
                    padding: 0;
                    .pay-result-info {
                        .pay-status {
                            background:#fff;
                            height: 205px;
                            .top {
                                margin-top: 20px;
                                margin-bottom: 33px;
                                icon {
                                    .im-icon {
                                        fill: #fff;
                                    }
                                }
                            }
                            .bill-top {
                                background:#fff;
                                height: 10px;
                                margin: 72px 16px 0;
                            }
                        }
                        .order-wrap {
                            .order-info  {
                                margin: -48px 24px 0;
                                .top {
                                    height: 10px;
                                    background:#fff;
                                }
                                .top-total {
                                    margin: 0 16px;
                                    padding: 12px 0 22px;
                                    border-bottom: 1px dashed #86889C;
                                    .lable {
                                        font-size: 12px;
                                        color: #1F1D29;
                                        font-weight: 400;
                                    }
                                }
                                .order {
                                    padding: 20px 0 0 16px;
                                    .scorll-wrap {
                                        max-height: 400px;
                                    }
                                    .goods-wrap {
                                        display: flex!important;
                                        align-items: flex-start!important;
                                        .g-left {
                                            padding-left: 0;
                                        }
                                    }
                                    .item {
                                        font-size: 12px;
                                        padding: 0 0 10px 0;
                                        &::before {
                                            display: none;
                                        }
                                        .left {
                                            width: 40%;
                                            padding-right: 0;
                                        }
                                        .right {
                                            width: 77%;
                                            padding-left: 0;
                                            padding-right: 8px;
                                            color: #1F1D29;
                                        }
                                        &.subTotal,&.tax {
                                            .right {
                                                text-align: right;
                                            }
                                        }
                                    }
                                    .item.mobile-justify-left {
                                        display: flex;
                                        justify-content: flex-start!important;
                                        .goods {
                                            padding-left: 0;
                                            padding-right: 8px;
                                            width: 77%;
                                            .g-item {
                                                font-size: 14px;
                                                padding: 0;
                                                color: #1F1D29;
                                            }
                                        }
                                    }
                                    .item.goods-wrap, .subTotal, .tax {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .im-model-wrap.pay-result.paid {
            .im-model {
                .mobile-head {
                    background: #00C970;
                }
                .im-model-body {
                    .pay-status {
                        background: #00C970;
                        .top {
                            padding-left: 24px;
                        }
                        .bill-top {
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #00C970;
                        }
                    }
                    .order-wrap {
                        .order-info {
                            .top {
                                background: linear-gradient(180deg, rgba(0, 171, 95, 0.4) 0%, rgba(0, 171, 95, 0) 100%);
                            }
                        }
                    }
                }
            }
        }
        .im-model-wrap.pay-result.unpaid,
        .im-model-wrap.pay-result.cancelled,
        .im-model-wrap.pay-result.refund_pending,
        .im-model-wrap.pay-result.refunded,
        .im-model-wrap.pay-result.refund_failed,
        .im-model-wrap.pay-result.refund_cancelled {
            .im-model {
                .mobile-head {
                    background: #c9c9d4;
                }
                .im-model-body {
                    .pay-status {
                        background: #c9c9d4;
                        .bill-top {
                            background: #86889C;
                        }
                    }
                    .order-wrap {
                        .order-info {
                            .top {
                                background: linear-gradient(180deg, #86889C 0%, rgba(201, 201, 212, 0) 100%);
                            }
                        }
                    }
                }
            }
        }
        .im-model-wrap.pay-result.pending {
            .im-model {
                .mobile-head {
                    background: #5A47EE;
                }
                .im-model-body {
                    .pay-status {
                        background: #5A47EE;
                        .bill-top {
                             background: #4D3CCA;
                        }
                    }
                    .order-wrap {
                        .order-info {
                            .top {
                                background: linear-gradient(180deg, rgba(92, 111, 254, 0.4) 0%, rgba(92, 111, 254, 0) 100%);
                            }
                        }
                    }
                }
            }
        }
        .im-model-wrap.pay-result.failed {
            .im-model {
                .mobile-head {
                    background: #F16B67;
                }
                .im-model-body {
                    .pay-status {
                        background: #F16B67;
                        .bill-top {
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F16B67;
                        }
                    }
                    .order-wrap {
                        .order-info {
                            .top {
                                background: linear-gradient(180deg, rgba(241, 107, 103, 0.4) 0%, rgba(241, 107, 103, 0) 100%);
                            }
                        }
                    }
                }
            }
        }
    }
</style>
