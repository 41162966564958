<template>
  <Form ref="formRef" layout="vertical" :model="formData" :rules="getFormRules" class="mt-[32px]">
    <FormItem prop="email" :label="$t('siteBuild.header.email')" class="enter-x">
      <Input
        v-model.trim="formData.email"
        :style="mainButtonColor"
        class="mt-2"
        size="large"
        :clearable="true"
        autocomplete="new-password"
        :placeholder="$t('siteBuild.header.email')"
      />
    </FormItem>
    <FormItem prop="password" :label="$t('siteBuild.header.password')" class="enter-x">
      <InputPassword
        v-model.trim="formData.password"
        :style="mainButtonColor"
        class="mt-2"
        size="large"
        name="register-pass"
        type="password"
        :clearable="true"
        autocomplete="new-password"
        :placeholder="$t('siteBuild.header.password')"
        @input="onPasswordChange"
      />
      <div v-if="formData.password" class="rules-box">
        <div class="item mt-2" :class="{ 'is-success': passwordResult.len, 'is-error': !passwordResult.len }">
          <span class="circle" />
          <span>{{ $t('siteBuild.register.tip1') }}</span>
        </div>
        <div class="item mt-1" :class="{ 'is-success': passwordResult.letter, 'is-error': !passwordResult.letter }">
          <span class="circle" />
          <span>{{ $t('siteBuild.register.tip2') }}</span>
        </div>
        <div class="item mt-1" :class="{ 'is-success': passwordResult.number, 'is-error': !passwordResult.number }">
          <span class="circle" />
          <span>{{ $t('siteBuild.register.tip3') }}</span>
        </div>
      </div>
    </FormItem>
    <FormItem>
      <Button
        type="primary"
        :class="!isMobile ? 'w-full h-[56px]  mt-[16px]' : 'w-full h-[48px]'"
        size="large"
        block
        :style="mainButtonColor"
        :normal="true"
        :loading="loading || loginLoading"
        :disabled="registerBtnDisabled"
        @click="handleRegister"
      >{{ $t('siteBuild.header.registerButton') }}</Button>
    </FormItem>
  </Form>
</template>
<script>
import colorMixin from './colorMixins'
import mobileMixin from './mobileMixins'
import { useFormRules, useLoginState, useFormValid } from './useLogin'
import { Form, FormItem, Input, } from '~/site/components/forms'
import Button from '~/site/components/forms/button'

export default {
  name: "RegisterForm",
  components: {
    Input,
    InputPassword: Input,
    Form,
    FormItem,
    Button,
  },
  mixins: [colorMixin, mobileMixin],
  props: {
    loginLoading: {
      type: Boolean,
      default: false,
    },
    siteId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: '',
      required: true
    },

    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { registerFormRules } = useFormRules(this.formData, this);
    const { handleBackLogin } = useLoginState()
    return {
      formData: {
        email: '', // 543803983@qq.com
        password: '', // Qw
      },
      loading: false,
      handleBackLogin,
      sendMailSuccess: false,
      getFormRules: registerFormRules,
      passwordResult: {
        len: false,
        number: false,
        letter: false
      }
    }
  },
  computed: {
    sendCodeBtnDisabled() {
      return !(this.formData.email)
    },
    registerBtnDisabled() {
      if (this.editing) {
        return false
      }
      const passwordError = Object.values(this.passwordResult).includes(false)
      const emailRight = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.formData.email)
      return !(!passwordError && emailRight)
    }
  },
  created() {
  },
  methods: {
    onPasswordChange(value) {
      const numberReg = /\d/
      const min = 8
      const letterReg = /[a-z]/i
      this.passwordResult.number = numberReg.test(value)
      this.passwordResult.letter = letterReg.test(value)
      this.passwordResult.len = value.length >= min
    },
    handlerBack() {
      this.handleBackLogin()
    },
    /**
     * 商城用户注册
     */
    handleRegister() {
      if (this.editing) {
        return
      }
      const { validForm } = useFormValid(this.$refs.formRef)
      const { email, password } = this.formData
      // this.$logger.captureMessage(email + '注册校验')
      validForm().then((data) => {
        this.loading = true
        // this.$logger.captureMessage(email + '注册校验成功,注册中')
        this.$api.siteBuild.registerUser({
          email,
          site_id: this.siteId,
          lang: this.$cookies.get('lang'),
          kop_customer: {
            email,
            password
          }
        }).then(() => {
          // this.$logger.captureMessage(email + '注册成功')
          this.$emit('login', { ...this.formData })
        }).catch((res) => {
          this.sendMailSuccess = false
          // this.$logger.captureMessage(email + '注册失败', res)
          this.$emit('show-alert', res, 'error')
        }).finally(() => {
          this.loading = false
        })
      });
    }
  }
}
</script>

<style lang="less">
.register-form {
  .rules-box {
    color: @text-color-8e;
    .item {
      display: flex;
      align-items: center;
      color: @text-color-8e;
      &.is-success {
        color: @success-color;
      }
      &.is-error {
        color: @error-color;
      }
      .circle {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: currentColor;
        margin-right: 8px;
      }
    }
    &+.site-form-item__error {
      display: none;
    }
  }
}
</style>
