// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-nav-wrap{position:relative}.edit-nav-wrap.is-bg{background-color:hsla(0,0%,100%,.05);border-radius:2px;padding:12px}.edit-nav-wrap .el-radio__input.is-checked .el-radio__inner{background-color:#3f54f2;border-color:#3f54f2}.edit-nav-wrap .el-icon-remove{position:absolute;right:10px;top:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
