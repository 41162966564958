<template>
  <div class="step-control mr-5 flex items-center">
    <prev-svg class="hover:text-primary step-control__prev" :class="{ 'disabled': !lastStep, 'text-white': lastStep }" @click="toAction('prev')" />
    <next-svg class="mx-5 hover:text-primary step-control__next" :class="{ 'disabled': !lastSaveStep, 'text-white': lastSaveStep }" @click="toAction('next')" />
  </div>
</template>

<script>
import { on } from '@/utils/dom'
import prevSvg from '~/assets/svg/icon/iconPrev.svg'
import nextSvg from '~/assets/svg/icon/iconNext.svg'
export default {
  name: 'StepControl',
  components: {
    prevSvg,
    nextSvg
  },
  props: {
    siteModel: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    lastStep() {
      return this.$store.state.editor.steps[this.$store.state.editor.steps.length - 1]
    },
    lastSaveStep() {
      return this.$store.state.editor.stepsStorage[this.$store.state.editor.stepsStorage.length - 1]
    }
  },
  mounted() {
    on(document, 'keydown', this.listenKeyDown)
  },
  methods: {
    listenKeyDown(e) {
      const metaKey = e.metaKey
      const ctrlKey = e.ctrlKey || metaKey
      const z = e.key === 'z'
      const x = e.key === 'x'
      if (ctrlKey && z) {
        e.preventDefault()
        this.toAction('prev')
      } else if (ctrlKey && x) {
        e.preventDefault()
        this.toAction('next')
      }
    },
    toAction(type) {
      const isPrev = type === 'prev'
      const step = isPrev ? this.lastStep : this.lastSaveStep
      step && this.siteModel.resetAction(this, step, type)
      this.$nextTick(() => {
        isPrev ? this.$store.commit('editor/REMOVE_LAST_STEP') : this.$store.commit('editor/REMOVE_LAST_STORAGE_STEP')
        this.SiteMenu.$bus.$emit('updateAlpha')
      })
    }
  }
}
</script>

<style scoped lang="less">
.step-control {
  &__prev, &__next {
    cursor: pointer;
    &.disabled {
      color: @text-color-secondary !important;
      cursor: not-allowed;
    }
  }
}
</style>
