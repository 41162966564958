<template>
  <!-- 删除 区块弹窗-->
  <BaseDialog :value="value" class="remove-block-dialog" width="448px" type="warning" :modal="true" :offset="[56,0,0,0]" :modal-append-to-body="true" :append-to-body="true" :title="title" @close="close" @change="close">
    <div class="remove-block__body">
      <div class="warning-title text-16 ">
        是否{{ title }}，如删除将丢弃该内容
      </div>
      <div class="warning-desc"></div>
    </div>
    <div class="divider"></div>
    <div class="remove-block_footer">
      <button class="normal-btn" @click.stop="close">保留</button>
      <button class="remove-btn" @click.stop="deleteBlock">删除</button>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '~/components/base/baseDialog'
export default {
  name: "DeleteBlockDialog",
  components:{
    BaseDialog,
  },
  model:{
    prop: 'value',
    event: 'change'
  },
  props:{
    title: {
      type: String,
      default: '删除区块'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    close(){
      this.$emit('change',false)
    },
    deleteBlock(){
      this.$emit('delete-block')
    }
  }
}
</script>

<style scoped>
.icon-error{
  width: 16px;
  height: 16px;
}
</style>
