import { render, staticRenderFns } from "./deviceTab.vue?vue&type=template&id=710c0684"
import script from "./deviceTab.vue?vue&type=script&lang=js"
export * from "./deviceTab.vue?vue&type=script&lang=js"
import style0 from "./deviceTab.vue?vue&type=style&index=0&id=710c0684&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports