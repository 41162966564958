<template>
  <div class="pwa-widget-edit">
    <div class="tip">
      <span>使用PWA功能，用户可以将网页应用安装至桌面，方便其快速访问。应用图标使用 SEO图标。</span>
    </div>
    <menu-item label="是否启用PWA">
      <menu-switch v-model="model.enabled" label="状态" />
    </menu-item>
<!--    <menu-item label="移动端描述图片">-->
<!--      <menu-background-image :target="model.guidBackground" target-key="guidBackground" event-key="pwa-guid-image" />-->
<!--    </menu-item>-->
    <menu-item label="按钮名称">
      <base-input v-model="model.buttonName" size="small" />
    </menu-item>
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue"
import BaseInput from "~/components/base/baseInput.vue"
import MenuSwitch from "~/components/menu/menuSwitch.vue"

export default {
  components: {MenuSwitch, BaseInput, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  }
}
</script>



<style scoped lang="less">

</style>
