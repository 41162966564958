import { render, staticRenderFns } from "./ImModel.vue?vue&type=template&id=44c60322&scoped=true"
import script from "./ImModel.vue?vue&type=script&lang=js"
export * from "./ImModel.vue?vue&type=script&lang=js"
import style0 from "./ImModel.vue?vue&type=style&index=0&id=44c60322&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c60322",
  null
  
)

export default component.exports