// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".server-popper{z-index:3011!important}@media (max-width:1440px){.bind-user__content{max-height:400px;overflow:auto;padding:0 10px;position:relative;z-index:3030}.bind-user__content::-webkit-scrollbar-thumb{background-color:#dedfe0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
