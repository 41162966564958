// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".store-list__content{margin:0 auto;max-width:1200px}.store-list__content .type-area{align-items:center;display:flex;overflow-x:auto}.store-list__content .type-area .item{cursor:pointer;flex-shrink:0;padding:10px 20px;position:relative;text-align:center;width:25%}.store-list__content .type-area .item .line{background-color:var(--button-background-color);bottom:0;height:2px;left:50%;position:absolute;transform:translate(-50%);width:80px}.store-list__content .goods-area{display:flex;flex-wrap:wrap}.store-list__content .goods-area .no-data{left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}.store-list__content .goods-area .item{padding:8px;width:33.333333%}.store-list.is-mobile .goods-area{max-height:480px;overflow-y:auto}.store-list.is-mobile .goods-area .item{margin-bottom:10px;padding:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
