
<template>
  <div class="flex coupon-item items-center" :class="{ 'is-selected': selected }">
    <div class="left-thumb w-1/3 text-center">
      <div class="text-16 font-bold text-white">{{ offText }}</div>
    </div>
    <div class="right-info w-2/3 flex items-center justify-between">
      <div class="text-black ml-4">
        <div class="text-14">{{ upperText }}</div>
        <div class="time mt-4">有效期：{{ timeText }}</div>
      </div>
      <div v-if="type === '1'" class="use-btn" @click="handleToHome">使用</div>
      <div v-if="type === '2'" class=" text-black">已使用</div>
      <div v-if="type === '3'" class=" text-black">已失效</div>
      <div class="select-box" @click.prevent="handleToggleSelect">
        <i class="el-icon-check" />
      </div>
    </div>
  </div>

</template>

<script>
import { ForeverTimeValue } from "~/enums/date";
import { countdown, formatZoneTime } from "~/utils";

export default {
  name: "CouponItem",
  components: {
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countTime: '',
      selected: false
    }
  },
  computed: {
    currencyLabel() {
      return this.$store.state.project.currencieMap?.[this.data.country_currency]?.label
    },
    upperText() {
      const { is_up_limit, up_limit_amount, amount, currency_type, vc_name } = this.data
      const label = currency_type === 1 ? vc_name : this.currencyLabel
      return is_up_limit ? `save up to ${label} ${up_limit_amount}` : `over ${label} ${amount}`
    },
    timeText() {
      const isForever = this.data.valid_end === ForeverTimeValue
      return this.data.valid_type === 2 ? this.countTime : (isForever ? '永久' : formatZoneTime(this.data.valid_end * 1000).text)
    },
    offText() {
      const number = (10 - this.data.discount) * 10
      return `${number}% OFF`
    }
  },
  created() {
    countdown(this.data.expired_time, this)
  },
  methods: {
    handleToggleSelect() {
      this.selected = !this.selected
    },
    handleToHome() {
      this.SitePage.toHome()
    }
  }
}
</script>


<style scoped lang="less">
.coupon-item {
  padding: 15px;
  border: 1px solid #dedede;
  border-radius: 2px;
  .left-thumb {
    background-color: @primary-1;
    padding: 5px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    .over {
      color: white;
    }
  }
  .right-info {
    position: relative;
    .use-btn {
      background-color: @primary-1;
      color: white;
      padding: 5px 12px;
      border-radius: 2px;
      cursor: pointer;
    }
    .select-box {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 30px 30px 0;
      border-color: transparent #dedede transparent transparent;
      position: absolute;
      right: -15px;
      top: -17px;
      cursor: pointer;
      .el-icon-check {
        font-size: 15px;
        color: white;
        position: relative;
        left: 12px;
        font-weight: bold;
      }
      &.is-selected {
        border-color: transparent @primary-1 transparent transparent;
      }
    }
  }
  &.is-selected {
    border-color: @primary-1;
    .select-box {
      border-color: transparent @primary-1 transparent transparent;
    }
  }
}
</style>
