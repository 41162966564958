// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".font-color-popper .el-popover__title{font-size:14px}.font-color-popper .content .el-color-picker{height:16px;width:100%}.font-color-popper .content .el-color-picker__trigger{border:none;height:100%;padding:0;width:100%}.font-color-popper .content .el-color-picker__color{border:none}.font-color-popper .content .el-color-picker__icon{color:#000;font-weight:700}.font-color-popper .content .color-list{display:grid;grid-template-columns:repeat(10,1fr);grid-gap:5px;gap:5px}.font-color-popper .content .item{border:1px solid transparent;cursor:pointer;height:16px;width:16px}.font-color-popper .content .item:hover{border-color:red}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
