// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rich-button__text{display:-webkit-box;max-width:100%;min-width:70px;position:relative;width:100%;-webkit-line-clamp:2;-webkit-box-orient:vertical;color:var(--button-text-color);font-family:var(--main-font);line-height:1.2em;overflow:hidden;text-align:center;text-overflow:ellipsis}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
