<template>
  <div class="module-navigation-edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
      <menu-background-image class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="功能设置">
      <menu-switch v-model="model.enable" class="mt-4" label="是否开启模块导航" />
    </menu-item>
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";

export default {
  name: "ModuleNavigationEdit",
  components: {MenuSwitch, MenuBackgroundImage, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="less">

</style>
