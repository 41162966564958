<template>
  <Input
         v-model="defaultValue"
         :class="prefixCls"
         v-bind="$attrs"
         :rows="rows || 10"
         :size="size"
         v-on="$listeners"
         @change="onChange"
         @blur="onBlur"
  >
    <template slot="prepend">
      <slot name="prepend" />
    </template>
    <slot></slot>
  </Input>
</template>

<script>
import {Input} from 'element-ui'
import {useGlobSetting} from '~/hooks/useGlobSetting'
import {insertStep} from "~/utils/event";

export default {
  name: "BaseInput",
  components: {
    Input
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    keys: {
      type: Array,
      default() {
        return []
      }
    },
    targetKey: {
      type: String,
      default: ''
    },
    modelKey: {
      type: String,
      default: ''
    },
    targetIndex: {
      type: [String, Number],
      default: '',
    },
    rows:{
      type: [String, Number],
      default: 10,
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  data() {
    const {prefixCls} = useGlobSetting()
    return {
      defaultValue: '',
      prefixCls: prefixCls + '-input'
    }
  },
  watch:{
    value(val){
      this.defaultValue = val
    }
  },
  created() {
    this.defaultValue = this.value
  },
  methods: {
    onChange() {
      insertStep(this, [...this.keys, this.modelKey, this.targetIndex, this.targetKey], this.value)
      this.$emit('change', this.value)
    },
    onBlur() {
      this.$emit('input', this.defaultValue)
    }
  }
}
</script>

<style lang="less" scoped>
@prefix-cls: ~'@{namespace}-input';
.@{prefix-cls} {
  :hover{
    //border: 1px @primary-color solid;
  }
  /deep/ .el-input-group__prepend {
    background-color: @fill-color-2;
    border: none;
  }
  /deep/ input  {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 12px;
    font-weight: normal;
    &:focus{
        border: 1px solid rgba(255, 255, 255, 0.6);
        color: #fff;
    }
    &:hover {
      border: 1px solid @text-color-secondary;
    }
  }
    /deep/ textarea {
      background: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.3);
      border: 1px solid transparent;
      border-radius: 2px;

      &:focus {
        //background: #151724;
        border: 1px solid rgba(255, 255, 255, 0.6);
        color: #fff;
      }
    }
}
</style>
