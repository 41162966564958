<template>
  <div class="recharge-edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
      <menu-background-image :target="model.background" target-key="background" class="mt-2" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" />
      <menu-switch v-model="model.quoteVisible" class="mt-2" label="赠送额度" />
    </menu-item>
    <menu-item label="桌面端排版方式">
      <im-select v-model="model.column" class="w-full" size="small" :options="columnOptions" />
    </menu-item>
    <menu-item label="移动端排版方式">
      <im-select v-model="model.mobileColumn" class="w-full" size="small" :options="mobileColumnOptions" />
    </menu-item>
    <menu-item label="充值背景圆角">
      <menu-slider v-model="model.radius" />
    </menu-item>
    <menu-item label="充值活动logo">
      <menu-background-image :target="model.activityLogo" target-key="activityLogo" event-key="recharge-activity-logo" class="mt-2" />
    </menu-item>
    <menu-item label="充值板块">
      <div class="recharge-edit__list">
        <div v-for="(item, index) in model.list" :key="index" class="item rounded-sm">
          <div class="flex items-center justify-between w-full mb-2">
            <div class="text-12">面额板块{{ index + 1 }}</div>
            <i class="el-icon-delete cursor-pointer hover:text-danger" @click="handleRemove(index)" />
          </div>
          <im-select v-model="item.faceId" size="small" :options="$store.state.project.rechargeValueList" />
          <menu-item label="充值背景" size-tip="建议比例(4:3)">
            <menu-fill :target="item.background" />
            <menu-background-image :target="item.background" class="mt-2" />
          </menu-item>
          <menu-item label="面额字体颜色">
            <menu-fill :target="item" color-key="faceColor" eye-key="faceColorShow" />
          </menu-item>
        </div>
      </div>
    </menu-item>
    <menu-button class="mt-4" icon="add" @click.native="handleAddItem">添加充值板块</menu-button>
    <menu-button class="mt-4" icon="add" :help-text="$t('menu.copyBlock')" @click.native.stop="handleClone">{{ $t('menu.createCopy') }}</menu-button>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import MenuSlider from "~/components/menu/menuSlider.vue";
import MenuButton from "~/components/menu/menuButton.vue";
import ImSelect from "~/components/common/ImSelect.vue";

export default {
  name: 'RechargeWidgetEdit',
  components: {
    ImSelect,
    MenuButton,
    MenuSlider,
    MenuSwitch,
    MenuBackgroundImage,
    MenuFill,
    MenuItem
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      mobileColumnOptions: [
        {
          label: '2列',
          value: 2
        },
        {
          label: '3列',
          value: 3
        }
      ],
      columnOptions: [
        {
          label: '4列',
          value: 4
        },
        {
          label: '5列',
          value: 5
        },
        {
          label: '6列',
          value: 6
        }
      ]
    }
  },
  methods: {
    handleClone() {
      //
    },
    handleAddItem() {
      this.model.__addItem()
    },
    handleRemove(index) {
      this.model.list.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="less">
.recharge-edit {
  &__list {
    .item {
      background-color: @fill-color-2;
      padding: 12px 10px 12px 8px;
      margin-bottom: 16px;
    }
  }
}
</style>
