<template>
  <el-popover :visible-arrow="false" title="文字描边" popper-class="font-stroke-popper">
    <div class="content flex justify-center">
      <el-color-picker v-model="color" @change="onChange" />
    </div>
    <i slot="reference" class="iconfont icon-miaobian" title="文字描边" />
  </el-popover>
</template>

<script>
import { Popover, ColorPicker } from "element-ui";
export default {
  name: "FontStroke",
  components: {
    [Popover.name]: Popover,
    [ColorPicker.name]: ColorPicker
  },
  data() {
    return {
      color: ''
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.color)
    },
    init(value) {
      this.color = value
    }
  }
}
</script>

<style lang="less">
.font-stroke-popper {
  min-width: 65px;
  .el-popover__title {
    font-size: 14px;
  }
}

</style>
<style scoped lang="less">
/deep/ .el-popover__reference-wrapper {
  display: flex;
}
</style>
