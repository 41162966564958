<template>
  <div class="menu-btn-wrap text-white flex items-center justify-center cursor-pointer rounded border border-lineColor-2 border-dashed hover:border-primary h-[32px] px-2 hover:bg-primary-light text-12" :class="type">
    <template v-if="type === 'ghost'">
      <component :is="icon + 'Svg'"></component>
      <span class="ml-10px">
        <slot />
      </span>
    </template>
    <template v-else-if="type === 'plain'">
      <slot name="icon">
        <component :is="icon + 'Svg'" class="svg-icon" />
      </slot>
      <span>
        <slot></slot>
      </span>
      <el-tooltip v-if="helpText" effect="dark" :content="helpText" placement="top" popper-class="help-popper">
        <help-svg class="svg-icon ml-2" />
      </el-tooltip>
    </template>
    <template v-if="type === 'ghost-copy'">
      <component :is="icon + 'Svg'" class="svg-icon"></component>
      <span>
        <slot />
      </span>
      <el-tooltip v-if="helpText" effect="dark" placement="top" popper-class="help-popper" :content="helpText">
        <help-svg class="svg-icon ml-2" />
      </el-tooltip>
    </template>
  </div>
</template>

<script>
import deleteSvg from '~/assets/svg/icon/delete.svg'
import removeSvg from '~/assets/svg/icon/iconDelete.svg'
import addSvg from '~/assets/svg/icon/iconUpload.svg'
import copySvg from '~/assets/svg/icon/copy.svg'
import helpSvg from '~/assets/svg/icon/help.svg'
import addGreenSvg from '~/assets/svg/icon/add-green.svg'
export default {
  components: {
    deleteSvg,
    removeSvg,
    copySvg,
    helpSvg,
    addGreenSvg,
    addSvg
  },
  props: {
    type: {
      type: String,
      default: 'plain'
    },
    helpText: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less">
.help-popper.is-dark {
  background-color: @select-bg;
  opacity: 1 !important;
  .popper__arrow {
    border-top-color: @select-bg !important;
    border-bottom-color: @select-bg !important;
    &::after {
      border-top-color: @select-bg !important;
      border-bottom-color: @select-bg !important;
    }
  }
}

.menu-btn-wrap {
  color: @text-7;
  .svg-icon {
    fill: currentColor;
    margin-right: 8px;
  }
  &.ghost, &.ghost-copy {
    padding: 8px 16px;
    border: 1px dashed @text-color-alpha-3;
    border-radius: 4px;
    &:hover {
      border-color: @text-color-secondary;
    }
  }
}
</style>
