<template>
  <div id="site-body" class="coupon-page" :style="getStyle" :class="{ 'is-mobile': deviceType === DeviceEnum.MOBILE }">
    <header-widget v-if="model.widgetList?.[0]" :site="model" :device="deviceType" :model="model.widgetList[0]" />
    <div class="coupon-page__content h-full">
      <el-menu v-if="deviceType !== DeviceEnum.MOBILE" class="menu" default-active="1">
        <el-menu-item index="1">
          <i class="el-icon-tickets" />
          <span slot="title">优惠券</span>
        </el-menu-item>
      </el-menu>
      <div v-loading="loading" class="list w-full h-full bg-white p-5">
        <el-tabs v-model="tab" @tab-click="onTabChange">
          <el-tab-pane label="未使用" name="1">
            <div class="list-area">
              <coupon-item v-for="(item, index) in list" :key="index" :data="item" type="1" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="已使用" name="2">
            <div class="list-area">
              <coupon-item v-for="(item, index) in list" :key="index" type="2" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="已过期/已失效" name="3">
            <div class="list-area">
              <coupon-item v-for="(item, index) in list" :key="index" type="3" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="规则说明" name="4"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { Menu, MenuItem, Tabs, TabPane } from "element-ui";
import CouponItem from "./couponItem.vue";
import HeaderWidget from "~/site/widgets/headerWidget/index.vue";
import {DeviceEnum} from "~/enums/deviceEnum";

export default {
  components: {
    HeaderWidget,
    [Menu.name]: Menu,
    [MenuItem.name]: MenuItem,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    CouponItem
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tab: '1',
      list: [],
      loading: false
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    deviceType() {
      return this.$store.state.device.device
    },
    getStyle() {
      let style = {}
      const { globalSetting } = this.model
      if (globalSetting) {
        style = globalSetting.__vars
      }
      return style
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.queryList()
    },
    async queryList() {
      const params = {
        project_id: this.$store.state.project.projectId,
        status: Number(this.tab)
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.activity.couponList(params))
      this.loading = false
      if (!err) {
        this.list = res.list || []
      }
    },
    onTabChange() {
      this.list = []
      this.queryList()
    }
  }
}
</script>

<style lang="less">
.coupon-page {
  background-color: #f0efef;
  &__content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    .menu {
      width: 200px;
      height: 100%;
      flex-shrink: 0;
    }
  }
  .list-area {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    overflow: auto;
    height: calc(100vh - 160px);
  }
  &.is-mobile {
    .list-area {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
