// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dialog-widget{bottom:0;left:0;position:absolute!important;right:0;top:0;z-index:3001}.dialog-widget.is-mobile{max-width:100%}.dialog-widget.is-mobile .dialog-widget__content,.dialog-widget.is-mobile .modal-wrapper{width:100%}.dialog-widget__close{align-items:center;border:1px solid;border-radius:100%;display:flex;font-size:20px;height:30px;justify-content:center;position:absolute;right:10px;top:10px;width:30px}.dialog-widget__content{bottom:0;height:-moz-fit-content;height:fit-content;left:0;margin:auto;position:absolute;right:0;top:0;width:-moz-fit-content;width:fit-content;z-index:3021}.dialog-widget__content .title{font-size:24px}.dialog-widget__content .ql-size-large{font-size:32px}.dialog-widget__content .modal-wrapper{background-color:transparent;background-color:initial;overflow:hidden;padding:20px;position:relative;width:720px}.dialog-widget__content .modal-wrapper .swiper-slide{padding:20px}.dialog-widget__content .modal-wrapper .swiper-slide .swiper-img img{max-height:360px}.dialog-widget__content .modal-wrapper .swiper-slide .cover-btn{left:50%;position:absolute!important;top:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
