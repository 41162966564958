var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.refresh)?_c('client-only',[_c('div',{class:{
    'carousel-wrap': true,
    'overflow-hidden': !_vm.editing,
    'carousel_wrap_desk': !_vm.isMobile,
    'carousel_wrap_mobile': _vm.isMobile
  }},[_c('div',{ref:"swiper",staticClass:"swiper",attrs:{"id":"swiper"}},[_c('div',{staticClass:"slide-block swiper-wrapper",class:_vm.model.effect},_vm._l((_vm.model.children),function(item){return _c('div',{key:item.id,staticClass:"h-auto swiper-slide",style:({ height: _vm.calculateHeight + 'px' }),on:{"click":function($event){return _vm.handleImgClick(item)}}},[_c('bg-style',{staticClass:"h-full",attrs:{"bg":_vm.isMobile ? item.mobileBackground : {
              backgroundImage: item.backgroundImage,
              backgroundSize: item.backgroundSize,
              backgroundRepeat: 'no-repeat',
              backgroundColor: item.backgroundColor,
              backgroundColorShow: item.backgroundColorShow,
              backgroundImageShow: item.backgroundImageShow,
              backgroundOpacity: item.backgroundOpacity
            }}},[_c('div',{staticClass:"h-full text_wrap"},[_c('im-row',{staticClass:"relative z-20 w-full h-full slide-item"},[_c('im-col',{staticClass:"flex flex-col justify-center h-full",attrs:{"lg":18,"xs":{ span: 24 }}},[(_vm.model.hasTitle)?_c('rich-text',{staticClass:"font-bold item_main-title swiper-no-swiping site-media",attrs:{"editing":_vm.editing,"disabled":!_vm.editing,"theme":"snow","placement":"bottom"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}):_vm._e(),_vm._v(" "),(_vm.model.hasDescribe)?_c('rich-text',{staticClass:"item_sub-title site-media swiper-no-swiping",attrs:{"editing":_vm.editing,"disabled":!_vm.editing,"theme":"snow","placement":"top"},model:{value:(item.subTitle),callback:function ($$v) {_vm.$set(item, "subTitle", $$v)},expression:"item.subTitle"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap items-center item-left__main-button"},_vm._l((item.buttons),function(btn,btnIndex){return _c('rich-button',{key:btnIndex,staticClass:"carousel-collation swiper-no-swiping site-media link_btns",attrs:{"is-edit":false},nativeOn:{"click":function($event){return _vm.handleButtonClick(btn)}},model:{value:(btn.name),callback:function ($$v) {_vm.$set(btn, "name", $$v)},expression:"btn.name"}})}),1)],1)],1)],1)])],1)}),0),_vm._v(" "),(_vm.model.children.length > 1)?_c('div',{staticClass:"swiper-pagination"}):_vm._e(),_vm._v(" "),(_vm.model.hasController && _vm.model.children.length > 1)?_c('ul',{class:['swiper-prev', 'left-[10px]', { 'xl:left-[40px]': _vm.$store.getters.isDesktop }]},[_c('div',{staticClass:"button-prev",on:{"click":function($event){$event.stopPropagation();return _vm.handleChange('prev')}}},[_c('im-icon',{staticClass:"cursor-pointer",attrs:{"icon":"icon-lunbozuo"}})],1)]):_vm._e(),_vm._v(" "),(_vm.model.hasController && _vm.model.children.length > 1)?_c('ul',{class:['swiper-next', 'right-[10px]', {
          'xl:right-[40px]': _vm.$store.getters.isDesktop
        }]},[_c('div',{staticClass:"button-next",on:{"click":function($event){$event.stopPropagation();return _vm.handleChange('next')}}},[_c('im-icon',{attrs:{"icon":"icon-lunboyou"}})],1)]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }