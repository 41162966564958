// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".save-confirm__content .normal-btn{background-color:#fff;border:1px solid #dedede;border-radius:2px;color:#000;line-height:32px;padding:0 10px}.save-confirm__content .remove-btn{background-color:#ff6055;border:1px solid #ff6055;border-radius:4px;color:#fff;line-height:32px;padding:0 10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
