// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sign-info .el-drawer__header{display:none}.sign-info .el-drawer__body{padding:48px 20px 68px}.sign-info .el-dialog{border-radius:4px}.sign-info .el-dialog__header{display:none}.sign-info .el-dialog__body{padding:52px 50px}.sign-info__close{color:#000;cursor:pointer;font-size:20px;font-weight:700;position:absolute;right:24px;top:24px}.sign-info__close:hover{color:#ff6055}.sign-info__detail{background-color:#f5f5f5;border-radius:2px;color:#000;margin-top:12px;max-height:260px;overflow:auto;padding:20px}.sign-info__detail::-webkit-scrollbar-thumb{background-color:#999}.sign-info__detail .gift{color:var(--sign-text-color-50);margin-top:15px}.sign-info__detail .gift:first-of-type{margin-top:0}.sign-info__content .title{color:var(--sign-text-color);font-size:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
