import { render, staticRenderFns } from "./baseInput.vue?vue&type=template&id=538da837&scoped=true"
import script from "./baseInput.vue?vue&type=script&lang=js"
export * from "./baseInput.vue?vue&type=script&lang=js"
import style0 from "./baseInput.vue?vue&type=style&index=0&id=538da837&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538da837",
  null
  
)

export default component.exports